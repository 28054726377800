<div class="ds-title" style="margin-top: 2vw; line-height:0.7vw;" *ngIf="!title && settings.titleAndSubtitle">
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div class="flex-row">
      <div class="flex-md-column">
        <h1 style="font-size: 200%;">{{ settings.titleAndSubtitle.title | translate}}</h1>
      </div>
      <div class="flex-md-column" *ngIf="settings.toggle || settings.toggleSamePage" style="margin-left: 1vw;">
        <app-grid-toggle (toggleGridEvent)="togglePages($event)" [toggleStatus]="isParentPage"
          [toggle]="settings.toggle" [toggleSamePage]="settings.toggleSamePage"></app-grid-toggle>
      </div>
    </div>
    <div *ngIf="settings.icl" class="flex-column">
      <a class="btn-primary btn" href="https://vivienda.buenosaires.gob.ar/calculadora_alquiler"
        target="_blank">Calculadora
        ICL</a>
    </div>
    <div *ngIf="settings.csv" class="flex-column" style="margin-right: 2vw;">
      <button class="btn btn-secondary" (click)="downloadCSV()" [disabled]="loadingCSV">
        <fa-icon [icon]="iconExcel" size="lg"
          style="margin-right: 5px; color: white; vertical-align: middle;"></fa-icon>
        Descargar CSV</button>
    </div>
  </div>
  <div class="flex-row" *ngIf="settings.titleAndSubtitle.subtitle">
    <span style="font-size: 100%;">{{ settings.titleAndSubtitle.subtitle | translate}}</span>
  </div>
</div>
<div class="ds-title" style="margin-top: 2vw; line-height:0.7vw;" *ngIf="title">
  <div class="flex-row">
    <div class="flex-md-column">
      <h1 style="font-size: 200%;">{{ title }}</h1>
    </div>
  </div>
</div>

<div [ngClass]="{'ds-body': settings.titleAndSubtitle}">
  <div [ngStyle]="{'width': tableWidth}" class="table-grid table-responsive-sm">
    <div class="table-search-and-add">
      <div class="flex-md-column" *ngIf="buttonForCreation">
        <button class="btn btn-primary" (click)="addButton()" style="margin-bottom: 5px;">
          <fa-icon [icon]="iconPlus" size="lg"
            style="margin-right: 5px; color: white; vertical-align: middle;"></fa-icon>
          {{settings.buttontitle | translate}}</button>
      </div>
    </div>
    <app-filter-v2 (removeFilters)="appliedFilters = false" [allowedFilterTypes]="settings.filter"
      [orderByTypes]="settings.orderBy" style="margin-bottom: 1vh;" *ngIf="toggleFilters"></app-filter-v2>

    <loading-spinner [evaluation]="isLoaded">
      <!-- Search TODO -->
      <div class="table-search-and-add">
        <app-modal-container style="display: none;" [size]="size" [modalContainer]="formTemplate"
          [cancelButton]="cancelFormButton"></app-modal-container>
        <div class="flex-column" style="justify-content: flex-start; align-items:flex-start; width: 100%;">
          <div class="flex-row" style="justify-content: space-between; align-items: center; width: 100%;">
            <!-- <span class="filter-toggler hoverblue" (click)="turnOnFilters()" *ngIf="settings.filter">Ver Filtros</span> -->
            <div class="flex-col-10 search" style="margin-right: 10px; width: 100%;" *ngIf="search">
              <select [(ngModel)]="personType" class="form-control form-select search-select"
                aria-label="Default select example">
                <option [ngValue]="item.type" *ngFor="let item of settings.search">{{item.title | translate}}</option>
              </select>
              <app-search style="width: 100%;" [parentService]="contractService" [personType]="personType"
                [gridMode]="true" (clearSearchEvent)="clearSearch()" (setResultsEvent)="setResults($event)">
              </app-search>
            </div>
            <div class="flex-col-2" style="width: 15%;">
              <div class="flex-row">
                <div class="flex-col">
                  <button class="btn btn-outline-primary" style="font-weight: 600;" (click)="turnOnFilters()"
                    [ngClass]="{'filter-button': !appliedFilters}" *ngIf="settings.filter">{{ buttonText }} Filtros
                  </button>
                </div>
                <div class="flex-col" style="width: 10px;" *ngIf="appliedFilters"
                  [ngbTooltip]="'La tabla actual posee filtros'">
                  <fa-icon class="filtered" [icon]="iconFilter" size="2x"></fa-icon>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>

      <!-- Filter -->
      <ng-container *ngIf="renderData.length > 0 || paginatedArguments.pageNumber > 1 else Norows">
        <div class="table-template table-responsive-sm ">
          <!-- Table -->
          <table class="table table-md shadow-sm border rounded">
            <!-- Headers -->
            <thead>
              <tr class="grid-headers">
                <th scope="col" *ngIf="settings.withChecks || settings.withManyChecks">
                  <div class="flex-row ">
                    #
                  </div>
                </th>
                <th scope="col" *ngFor="let header of headers; let i = index" [ngStyle]="{width: header.width}">
                  <div class="flex-row " *ngIf="header">
                    {{ header.title | translate}}
                  </div>
                </th>
                <th scope="col" style="text-align: end; padding-right: 16px;" *ngIf="settings.actions.length > 0">
                  <!-- Acciones -->
                </th>
              </tr>
            </thead>
            <ng-template *ngIf="renderData.length == 0 else Render">
              <tbody>
                <div>{{'gridComponent.tableEmpty' | translate}}</div>
              </tbody>
            </ng-template>
          </table>
        </div>
        <!-- Paginator  -->
        <div *ngIf="totalPages > 0" class="paginator-component"
          style="justify-content:space-between; width: 100%; align-items: baseline;">
          <div>
            <span>{{footerMsg(settings.titleAndSubtitle?.title | translate)}}</span>
          </div>
          <div>
            <ngb-pagination (pageChange)="pageChanged($event)" [(page)]="paginatedArguments.pageNumber"
              [pageSize]="itemsPerPage" [collectionSize]="totalItems" [boundaryLinks]="true" [maxSize]="5">
              <ng-template ngbPaginationPrevious>Ant</ng-template>
              <ng-template ngbPaginationNext>Sig</ng-template>
            </ngb-pagination>
          </div>
          <div>
            <div class="row" style="align-items: baseline;">
              <div class="col">
                <label style="white-space: nowrap;">Filas por página:</label>
              </div>
              <div class="col">
                <select class="form-select" #pageSize (change)="changePageSize($event)">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- /Paginator  -->
      </ng-container>
    </loading-spinner>
  </div>

  <!-- Rows -->
  <ng-template #Render>
    <tbody class="shadow-sm">
      <tr class="grid-rows-style" *ngFor="let row of renderData; let ind = index">
        <td *ngIf="settings.withChecks">
          <div class="checkbox-container">
            <label class="checkbox-label">
              <input type="checkbox" (change)="returnSelectElement(row, checkbox, ind)" #checkbox
                [checked]="ind == currentSelectedElement?.index && paginatedArguments.pageNumber == currentSelectedElement?.page">
              <span class="checkbox-custom rectangular"></span>
            </label>
          </div>
        </td>
        <td *ngIf="settings.withManyChecks">
          <div class="checkbox-container">
            <label class="checkbox-label">
              <input type="checkbox" (change)="returnSelectedElements(row, checkbox, ind)" #checkbox
                [checked]="isSelected(ind)">
              <span class="checkbox-custom rectangular"></span>
            </label>
          </div>
        </td>
        <td *ngFor="let item of settings.columns; let j = index">
          <div class="flex-row">
            <fa-icon *ngIf="item.key == 'icon'" [icon]="iconService.getIcon(row[j])">
            </fa-icon>
            <span *ngIf="item.key == 'index'">
              {{paginatedArguments.pageSize * (paginatedArguments.pageNumber - 1) + ind + 1}}
            </span>
            <span *ngIf="item.key != 'icon' && item.key != 'index' && !item.type">
              <span *ngIf="checkArray(row[j])">
                <span *ngFor="let rowItem of row[j]; let rowIndex = index">
                  <span *ngIf="item.urlFunction" (click)="item.urlFunction(tableData[ind][item.key][rowIndex])"
                    [ngbTooltip]="'Ver más'" placement="bottom" class="hoverblue">
                    {{rowItem}}
                  </span>
                  <span *ngIf="!item.urlFunction">
                    {{rowItem}}
                  </span>
                </span>
              </span>
              <span *ngIf="!checkArray(row[j])">
                <span *ngIf="item.urlFunction" (click)="item.urlFunction(tableData[ind][item.key])"
                  [ngbTooltip]="'Ver más'" placement="bottom" class="hoverblue">
                  {{row[j]}}
                </span>
                <span *ngIf="!item.urlFunction">
                  {{row[j]}}
                </span>
              </span>
            </span>
            <span *ngIf="item.type == 'amount'">
              <span>
                {{row[j] | currency:
                (tableData[ind][item.currency] === 2 ? 'USD' : 'ARS') : (tableData[ind][item.currency] === 1 ?
                'symbol-narrow' :
                'symbol') : '1.0-0' }}
              </span>
            </span>
          </div>
        </td>
        <td NOWRAP *ngIf="settings.actions.length > 0" style="text-align: end;">
          <button type="button" class="button" placement="bottom" [style.background-color]="action.color"
            style="margin-right: 15px;" [ngbTooltip]="action.tooltip" (click)="action.function(rawData[ind])"
            *ngFor="let action of settings.actions">
            <div>
              <fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
              <div *ngIf="action.text">{{action.text}}</div>
            </div>
          </button>
        </td>
      </tr>
    </tbody>
  </ng-template>

  <!-- No Rows -->
  <ng-template #Norows>
    <!-- <div>{{'gridComponent.tableEmpty' | translate}}</div> -->
    <div class="empty border shadow-sm">
      <fa-icon style="margin-bottom: 5px;" [icon]="iconQuestion" size="4x"></fa-icon>
      <span *ngIf="customEmptyMessage">{{ customEmptyMessage }}</span>
      <span style="margin-bottom: 5px;" *ngIf="!customEmptyMessage">{{'gridComponent.tableEmpty' | translate}}</span>
    </div>
  </ng-template>
</div>