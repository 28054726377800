import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private apiService: ApiService) {}

  editMail(id, updateEmail) {
    return this.apiService.put(`/api/realEstateAgency/${id}`, updateEmail);
  }
}
