<div class="summary shadow-sm border-5" *ngIf="type != 'building'">
  <div class="persons box-limit">
    <div class="title">
      <span style="font-size: 1.2rem; font-weight: 500;"><a [routerLink]= "personUrl" class = "card-title hoverblue margin-right-10px">{{ fullName | uppercase }}</a></span>
      <div style="column-gap: 10px;">
        <fa-icon class="hoverred" [icon]="iconTrash" size="1x" style="grid-area: icon; margin-left: 5px;" [ngClass]="{'displayMe': display}" (click)="deleteEvent()"> </fa-icon>
        <fa-icon class="hoverred" [icon]="iconPen" size="1x" style="grid-area: icon; margin-left: 5px;" [ngClass]="{'displayMe': display}" (click)="formOpen()"> </fa-icon>
      </div>
    </div>
    <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Dirección:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 10px;">
          {{ address }}
        </span>
      </div>
    </div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Email:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 10px;">
          {{ email }}
        </span>
      </div>
    </div>
    <div *ngIf="phones.length > 0" class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Telefonos:</span>
          <div class="flex-column">
            <span style="margin-bottom: 0.5px" *ngFor="let item of phones">
            <app-whatsapp-chat [phoneNumber] = "item"></app-whatsapp-chat><i> - {{ item.description }}</i></span>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="summary shadow-sm border-5" style="width: 20vw;" *ngIf="type == 'building' && cardData != null">
  <div class="persons box-limit" style="max-height: fit-content;">
    <div class="title">
      <span style="font-size: 1.1rem; font-weight: 500;"><a [routerLink]= "personUrl" class = "card-title hoverblue margin-right-10px">{{ title | uppercase }}</a></span>
      <div style="column-gap: 10px;">
        <fa-icon class="hoverred" [icon]="iconTrash" size="1x" style="grid-area: icon; margin-left: 5px; justify-content: flex-end;" [ngClass]="{'displayMe': display}" (click)="deleteEvent()"> </fa-icon>
        <fa-icon class="hoverred" [icon]="iconPen" size="1x" style="grid-area: icon; margin-left: 5px; justify-content: flex-end;" [ngClass]="{'displayMe': display}" (click)="formOpen()"> </fa-icon>
      </div>
    </div>
    <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Dirección:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 3px;">
          {{ address }}
        </span>
      </div>
    </div>    
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Unidad:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 3px;">
          {{ identification }}
        </span>
      </div>
    </div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Tipo de propiedad:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 3px;">
          {{ propertyTypeId }}
        </span>
      </div>
    </div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Ambientes:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 3px;">
          {{ rooms }}
        </span>
      </div>
    </div>
    <div class="info">
      <div class="flex-column">
        <span style="font-size: 0.8rem; font-weight: 450;">Metros Cuadrados:</span>
        <span style="font-size: 0.9rem; font-weight: 525; margin-bottom: 10px;">
          {{ surface }}
        </span>
      </div>
    </div>
  </div>
</div>
