<div class="summary shadow-sm border-5 mb-2 border-1">
  <span style="font-size: 1.2rem; font-weight: 500;"><a [routerLink]= "personUrl" class = "card-title hoverblue margin-right-10px">{{ fullName | uppercase}}</a></span>
  <div class="line-div" style="margin-top: 6px; opacity: 30%;"></div>
  <div class="info">
    <div class="flex-column">
      <span style="font-size: 0.8rem; font-weight: 450;">Dirección:</span>
      <span style="font-size: 0.9rem; font-weight: 525;">
        {{address}}
      </span>
    </div>
  </div>
  <div class="info">
    <div class="flex-column">
      <span style="font-size: 0.8rem; font-weight: 450;">Email:</span>
      <span style="font-size: 0.9rem; font-weight: 525;">
        {{this.email}}
      </span>
    </div>
  </div>
  <div *ngIf="this.phones.length > 0"  class="info">
    <div class="flex-column">
      <span style="font-size: 0.8rem; font-weight: 450;">Telefonos:</span>
        <div class="flex-column">
          <span style="margin-bottom: 0.5px" *ngFor="let item of this.phones">
          <app-whatsapp-chat [phoneNumber] = "item"></app-whatsapp-chat><i> - {{item?.description }}</i></span>
          <span style="font-size: 0.9rem; font-weight: 525;" *ngIf="this.phones.length == 0">No hay información de contacto</span>
        </div>
    </div>
  </div>
</div>