<nav class="sidebar d-flex flex-column"
     [class.collapsed]="sidebarHidden"
     [@sidebarAnimation]="sidebarHidden ? 'collapsed' : 'expanded'"
     [@slideInAnimation]="'in'">
     <div class="sidebar-content flex-grow-1 overflow-auto mt-2 m-1">
     <ul class="list-unstyled m-0 p-0">
       <ng-container *ngFor="let link of linking; let f = first">
                
         <!-- Encabezado de sección -->
         <li *ngIf="link.line" class="section px-3 py-2 text-uppercase small opacity-75 text-white section-header"
             (click)="toggleSection(link.text)">
           <br *ngIf="!f">
           <div class="d-flex align-items-center">
             <span class="flex-grow-1 text-white ">{{ link.text }}</span>
             <fa-icon [icon]="isSectionCollapsed(link.text) ? angleDown : angleDown" class="ms-2 expand-icon"
                     [ngClass]="{'rotate': !isSectionCollapsed(link.text)}"></fa-icon>
           </div>
         </li>
                
         <!-- Elementos de menú para esta sección -->
         <div class="section-items"
               [@submenuAnimation]="isSectionCollapsed(link.text) ? 'closed' : 'open'"
              *ngIf="link.line">
           <ng-container *ngFor="let item of getMenuItemsForSection(link.text)">
             <li class="menu-item">
               <div class="hover-color rounded d-flex align-items-center text-decoration-none text-white px-3 py-2"
                    (click)="goToLink(item)"
                    [ngClass]="{'activatedLink': isActive(item) || item.active}"
                    style="cursor: pointer; transition: background 0.3s ease-in-out;">
                 <fa-icon [icon]="iconService.getIcon(item.icon)" class="me-3"></fa-icon>
                 <span class="flex-grow-1 text-white" *ngIf="!sidebarHidden">{{ item.title }}</span>
                 <fa-icon *ngIf="item.linkChildren?.length >= 1 && !item.showChildren(item)"
                          [icon]="angleUp"></fa-icon>
                 <fa-icon *ngIf="item.linkChildren?.length >= 1 && item.showChildren(item)"
                          [icon]="angleDown"></fa-icon>
               </div>
                              
               <!-- Submenú -->
               <ul *ngIf="item.linkChildren?.length > 0 && (item.showChildren(item) || item.active && item.showChildren(item))"
                   class="submenu mb-1 mt-1 list-unstyled ms-3"
                   [@submenuAnimation]="item.showChildren(item) ? 'open' : 'closed'">
                 <li *ngFor="let child of item.linkChildren">
                   <div class="hover-color rounded text-white px-3 py-2 d-block"
                        (click)="goToLinkChildren(child, item)"
                        [ngClass]="{'activatedLink': isActive(child)}"
                        style="cursor: pointer; transition: background 0.3s ease-in-out;">
                     <fa-icon *ngIf="child.icon"
                              [icon]="iconService.getIcon(child.icon)"
                              class="me-2 fa-xs"></fa-icon>
                     {{ child.title }}
                   </div>
                 </li>
               </ul>
             </li>
           </ng-container>
         </div>
                
        </ng-container>
     </ul>
   </div>
</nav>