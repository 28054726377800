import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstallmentSchemaForAdd } from 'src/app/core/models/InstallmentSchemaForAdd';
import { DateService } from 'src/app/services/date.service';
import { ContractForAdd } from 'src/app/core/models/ContractForAdd';

@Component({
  selector: 'app-fixed-contract-form',
  templateUrl: './fixed-contract-form.component.html',
  styleUrls: ['./fixed-contract-form.component.css'],
})
export class FixedContractFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;

  schemeType: number;
  isChecked: boolean;
  currencyTypeGuaranteeDeposit: number;
  guaranteeDepositCurrencyValue: number;
  adminAmountCurrencyType: number;
  adminAmountCurrencyValue: number;
  adminAmountPercentageValue: number;
  punitives: number;
  paymentExpiryDay: number;
  contractCurrency: number;
  editIndex: number;

  invalidAmount: boolean = false;
  invalidQuantity: boolean = false;

  fromDate: Date;
  toDate: Date;
  order: number;

  @Input() contractInput: ContractForAdd;
  @Input() createInMemory?: any = true;
  @Input() editData: any;
  @Input() edit: boolean = false;

  @Output() closeOrder = new EventEmitter();

  @ViewChildren('checkedForErrors') viewChildren!: QueryList<ElementRef>;

  fixedContractFormGroup = new UntypedFormGroup({
    quantity: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(50),
    ]),
    amount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(2147483647),
    ]),
  });

  constructor(private dateService: DateService) {}

  ngOnInit() {
    if (this.edit) {
      this.editIndex = this.editData.index;
      this.fixedContractFormGroup.patchValue({
        quantity: this.editData.quantity,
        amount: this.editData.amount,
        fromdate: this.dateService.transformToDateHash(this.editData.fromDate),
        todate: this.dateService.transformToDateHash(this.editData.toDate),
      });
    }
  }

  onSubmitFixedForm() {
    if (this.fixedContractFormGroup.invalid) {
      this.invalidAmount = false;
      this.invalidQuantity = false;
      if (this.fixedContractFormGroup.controls.quantity.errors) {
        this.invalidQuantity = true;
      }
      if (this.fixedContractFormGroup.controls.amount.errors) {
        this.invalidAmount = true;
      }
    } else {
      if (
        this.contractInput.installmentSchemas &&
        this.contractInput.installmentSchemas.length > 0
      ) {
        if (this.edit) {
          this.order = this.editData.order;
          this.fromDate = new Date(this.editData.fromDate);
        } else {
          this.order = this.contractInput.installmentSchemas.length + 1;
          this.fromDate = new Date(
            this.contractInput.installmentSchemas[
              this.contractInput.installmentSchemas.length - 1
            ].toDate,
          );
          this.fromDate.setHours(25);
        }

        this.toDate = new Date(this.fromDate);
        this.toDate.setMonth(
          new Date(this.fromDate).getMonth() +
            this.fixedContractFormGroup.value.quantity,
        );
      } else {
        this.contractInput.installmentSchemas = [];
        this.order = 1;
        this.fromDate = new Date(this.contractInput.fromDate);
        this.toDate = new Date(this.fromDate);
        this.toDate.setMonth(
          new Date(this.fromDate).getMonth() +
            this.fixedContractFormGroup.value.quantity,
        );
      }
      this.toDate.setHours(-23);

      let installmentFixedToSubmit: InstallmentSchemaForAdd = {
        order: this.order,
        quantity: this.fixedContractFormGroup.value.quantity,
        amount: this.fixedContractFormGroup.value.amount,
        fromDate: this.fromDate,
        toDate: this.toDate,
        contractId: 0,
      };

      if (this.edit) {
        if (this.createInMemory) {
          this.contractInput.installmentSchemas[this.editIndex] =
            installmentFixedToSubmit;
        }
      } else {
        if (this.createInMemory) {
          this.contractInput.installmentSchemas.push(installmentFixedToSubmit);
        } else {
          // this.addProperty();
        }
      }
      this.closeOrder.emit();
    }
  }
}
