import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/services/translate.service';

export interface ConfirmDialogSettings {
  title: string;
  subtitle: string;
  paragraphs?: string[];
  bindLogic?: boolean;
  btnadd?: boolean;
}



export interface ModalStock{
  id: number;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  @ViewChild('content') modal: TemplateRef<any>;

  @Input() modalBody: TemplateRef<any>;
  @Input() modalTitles: ConfirmDialogSettings;
  @Input() modalStock: ModalStock;
  btnadd : boolean;
  constructor(
    private modalService: NgbModal,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {}

  openModal() {
    let options = null;
    if (this.modalTitles.bindLogic) {
      const mouseUpHandler = (event: MouseEvent) => {
        event.stopPropagation();
      };
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
        }
      };

      document.addEventListener('mouseup', mouseUpHandler, true);
      document.addEventListener('keydown', keyDownHandler, true);

      options = { 
        beforeDismiss: () =>{
          document.removeEventListener('mouseup', mouseUpHandler, true);
          document.removeEventListener('keydown', keyDownHandler, true);
          return true;
        }
      }
    }
    return this.modalService.open(this.modal, options);
  }

  translate(text: string): string {
    return this.translateService.translate(text);
  }

  isShowing(bol: boolean): boolean {
    if (bol) {
      return true;
    }
    return false;
  }
}
