<nav class="navbar shadow-sm" #navbar [ngClass]="{'navbar-hidden': isNavbarHidden}">
  <!-- Sección izquierda: Logo y toggle sidebar -->
  <div class="navbar-left">
    <button class="toggle-sidebar mat-icon-button" (click)="toggleSidebar()" [ngbTooltip]="toolTip">
      <fa-icon [icon]="sidebarIcon" class="menu-icon"></fa-icon>
    </button>
    <img src="assets/img/baup-02.svg" (click)="redirect()">
    <div style="display: flex; flex-direction: row;">
      <span style="align-self: center;" class="badge text-bg-warning">BETA</span>
      <span
        style="color: rgb(160, 160, 160); letter-spacing: 1px; font-size: 0.9rem; font-weight: 450; margin-left: 5px;">
        v0.3.0</span>
    </div>
  </div>

  <!-- Sección central: Búsqueda -->
  <div class="contract-search">
    <div class="search-wrapper">
      <select (change)="personType = $event.target.value" class="selector mat-select">
        <option *ngFor="let person of personTypes | keyvalue: returnZero" [value]="person.value">
          {{person.key}}
        </option>
      </select>
      <app-search 
        [gridMode]="false" 
        [dashboardMode]="false" 
        [headerMode]="true" 
        [parentService]="contractService" 
        [personType]="personType" 
        (setResultsEvent)="getSearchResult($event)">
      </app-search>
      <button class="mat-button mat-raised-button mat-primary go-button" (click)="goToContract()">
        Ir
      </button>
    </div>
  </div>

  <!-- Sección derecha: Perfil de usuario -->
  <div class="navbar-right">
    <div class="avatar-container">
      <div class="avatar" (click)="toggleSubmenu()" #avatar>
        {{ avatarInitial | uppercase }}
      </div>
    </div>
    
    <!-- Submenú del usuario -->
    <div class="submenu" #submenu *ngIf="submenuVisible">
      <div class="submenu-header">
        <span *ngIf="auth.user$ | async as user" class="user-name">{{user.name}}</span>
      </div>
      <div class="submenu-item" (click)="redirectToAgent()">
        <fa-icon [icon]="userTie" class="submenu-icon"></fa-icon>
        <span>Usuario</span>
      </div>
      <div class="submenu-item" (click)="redirectToConfig()">
        <fa-icon [icon]="gear" class="submenu-icon"></fa-icon>
        <span>Configuración</span>
      </div>
      <div class="submenu-item">
        <fa-icon [icon]="exit" class="submenu-icon"></fa-icon>
        <app-auth-button></app-auth-button>
      </div>
    </div>
  </div>
</nav>