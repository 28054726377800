import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequirementService } from 'src/app/services/requirement.service';
import { DateService } from '../../services/date.service';
import { PaymentsService } from '../../services/payments.service';

@Component({
  selector: 'app-service-heatmap',
  templateUrl: './service-heatmap.component.html',
  styleUrls: ['./service-heatmap.component.scss'],
})
export class ServiceHeatmapComponent implements OnInit {
  @Input() sectorView: any;

  dias: string[] = [];
  isLoaded = false;
  semanas: any[] = [];
  cameraNames: string[] = [];
  camerasPagination = 36;
  currentPage = 1;
  totalPages = 0;
  totalItems = 0;
  maxSize = 10;
  fromItem = 0;
  toItem = 0;
  sectorID = 0;
  orderedMonths: string[] = [];

  yearMonths = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  weekDays = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];

  constructor(
    private router: Router,
    public printHtmlElementRef: ElementRef,
    requirementService: RequirementService,
    public dateService: DateService,
    private paymentService: PaymentsService,
  ) {
    paymentService.getPaymentPerYear().subscribe((result: any[]) => {
      this.semanas = result;
      this.isLoaded = true;
    });
  }

  private getRandomInt(min, max): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private crearDatosDummies(semanas: number, diasSemana: number, date: Date) {
    const daysList: Date[] = [];
    const dummiesCameras: any[][] = [];

    for (let i = 0; i < semanas; i++) {
      for (let j = 0; j < diasSemana; j++) {
        let data = {
          servicePaid: this.getRandomInt(1, 100),
          weekDay: date.toDateString,
        };
        // weekData.push(data)
        this.semanas.push(data);
      }
    }
  }

  ngOnInit() {
    let month = new Date().getMonth();
    for (let index = 0; index < this.yearMonths.length; index++) {
      let monthIndex = month - index;
      if (monthIndex < 0) {
        monthIndex = monthIndex + 12;
      }
      this.orderedMonths.unshift(this.yearMonths[monthIndex]);
    }
  }

  parseDate(val) {
    return this.dateService.transformToDateStringFromString(val);
  }
}
