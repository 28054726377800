<div class="flex-column search-form">
  <h1>Buscar {{searchTitle}}</h1>
  <hr>
  <label *ngIf="searchItem === 1">Busqueda por Dirección</label>
  <label *ngIf="searchItem === 2 || searchItem === 3 || searchItem === 4">Busqueda por Nombre, DNI</label>
  <input #searchBox class="form-control" type="text" [(ngModel)]="searchString" style="width: 15vw;">
  <div *ngIf="{stream: results$ | async} as observable">
    <span style="margin-top: 10px;" *ngIf="searching && searchString != ''">Buscando...</span>
    <span *ngIf="!searching && observable.stream?.result?.length > 0">Resultado de la Búsqueda</span>
    <span *ngIf="!searching && (observable.stream?.result?.length == 0 || observable.stream?.result == null) && searchString != ''">
      No se encontraron resultados</span>
    <table class="table" *ngIf="!searching && observable.stream?.result?.length > 0">
      <thead>
        <tr *ngIf="searchItem === 1">
          <th *ngFor="let header of buildingHeader">{{header | translate}}</th>
        </tr>
        <tr *ngIf="searchItem != 1">
          <th *ngFor="let header of personHeader">{{header | translate}}</th>
        </tr>
      </thead>
      <tbody *ngIf="searchItem === 1" >
        <tr *ngFor="let result of observable.stream?.result" class="hoverlightbluebg" (click)="selectElement(result)">
          <!-- <td *ngFor="let key of buildingKeys">{{result[key]}}</td> -->
          <td >{{result.address.streetAddress}} - {{result.address.city}}</td>
          <td >{{result.identification}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="searchItem === 2 || searchItem === 3" >
        <tr *ngFor="let result of observable.stream?.result" class="hoverlightbluebg" (click)="selectElement(result)">
          <td>{{result.user.person.fullName}}</td>
          <td>{{result.user.email}}</td>
          <td>{{result.user.person.documentId}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="searchItem === 4" >
        <tr *ngFor="let result of observable.stream?.result" class="hoverlightbluebg" (click)="selectElement(result)">
          <td>{{result.person.fullName}}</td>
          <td>{{result.email}}</td>
          <td>{{result.person.documentId}}</td>
        </tr>
      </tbody>
    </table>
    <div class="paginator-component" *ngIf="observable.stream?.result?.length > 0">
      <ngb-pagination (pageChange)="searchPageChanged($event)" [(page)]="page" [pageSize]="5" 
        [collectionSize]="observable.stream?.pagination.totalItems" [boundaryLinks]="true" [maxSize]="5">
        <ng-template ngbPaginationPrevious>Ant</ng-template>
        <ng-template ngbPaginationNext>Sig</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
