import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  faCalendar,
  faSearch,
  faPlus,
  faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { DocumentTypes } from 'src/app/core/models/DocumentTypes.enum';
import { PhoneNumber } from 'src/app/core/models/PhoneNumber';
import { UserType } from 'src/app/core/models/UserType.enum';
import { DateService } from 'src/app/services/date.service';
import { UsersService } from 'src/app/services/users.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
})
export class UserFormComponent implements OnInit {
  iconCalendar = faCalendar;
  iconSearch = faSearch;
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  userIndex: number;
  documentTypes: any[] = [];
  phoneContacts: PhoneNumber[] = [];
  submitted: boolean = false;

  userTypeCheck: boolean = true;
  userTypeToggle: UserType = UserType.Person;
  userType = UserType;

  @Input() edit?: boolean = false;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  userDataFormGroup = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl(''),
    documentId: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(2147483647),
    ]),
    documentType: new UntypedFormControl(''),
    streetAddress: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    isAdmin: new UntypedFormControl(false),
    rol: new UntypedFormControl(''),
  });

  constructor(
    public userService: UsersService,
    public dateService: DateService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    for (let item in DocumentTypes) {
      if (isNaN(Number(item))) {
        this.documentTypes.push({ text: item, value: DocumentTypes[item] });
      }
    }
    if (this.edit) {
      this.patchValueAddObjectType();
      this.userIndex = this.editData.id;
      this.phoneContacts = this.editData.user.person.phones;
    } else {
      this.userDataFormGroup.reset();
    }

    if (this.edit) {
      let date = this.editData.user.person.dateOfBirth;
      this.userIndex = this.editData.id;
      this.userDataFormGroup.patchValue({
        userName: this.editData.user.userName,
        dateOfBirth: date
          ? this.dateService.transformToDateHash(new Date(date))
          : null,
        email: this.editData.user.email,
        isAdmin: false,
        role: this.editData.user.role || 'user',
        firstName: this.editData.user.person.firstName,
        lastName: this.editData.user.person.lastName,
        documentId: this.editData.user.person.documentId,
        documentType: this.editData.user.person.documentType,
        streetAddress: this.editData.user.person.address.streetAddress,
        city: this.editData.user.person.address.city,
        state: this.editData.user.person.address.state,
        zipCode: this.editData.user.person.address.zipCode,
      });
    }
  }

  onAdminChange(event: any) {
    const isAdmin = event.target.checked;
    this.userDataFormGroup.patchValue({
      role: isAdmin ? 'admin' : 'user',
    });
  }

  onSubmit() {
    let userToSubmit: any;
    let date = this.userDataFormGroup.value.dateOfBirth
      ? this.userDataFormGroup.value.dateOfBirth
      : null;

    userToSubmit = {
      userName: this.userDataFormGroup.value.userName
        ? this.userDataFormGroup.value.userName
        : null,
      email: this.userDataFormGroup.value.email
        ? this.userDataFormGroup.value.email
        : null,
      dateOfBirth: date
        ? this.dateService.transformToDateObject(
            date?.day,
            date?.month,
            date?.year,
          )
        : null,
      isAdmin: false,
      documentId: this.userDataFormGroup.value.documentId
        ? this.userDataFormGroup.value.documentId
        : null,
      documentType: this.userDataFormGroup.value.documentType
        ? this.userDataFormGroup.value.documentType
        : null,
      firstName: this.userDataFormGroup.value.firstName
        ? this.userDataFormGroup.value.firstName
        : '',
      lastName: this.userDataFormGroup.value.lastName
        ? this.userDataFormGroup.value.lastName
        : null,
      streetAddress: this.userDataFormGroup.value.streetAddress
        ? this.userDataFormGroup.value.streetAddress
        : null,
      city: this.userDataFormGroup.value.city
        ? this.userDataFormGroup.value.city
        : null,
      state: this.userDataFormGroup.value.state
        ? this.userDataFormGroup.value.state
        : null,
      zipCode: this.userDataFormGroup.value.zipCode
        ? this.userDataFormGroup.value.zipCode
        : null,
      phones: this.phoneContacts,
      role:
        this.userDataFormGroup.get('isAdmin').value === true ? 'admin' : 'user',
    };
    console.log('Role being sent:', userToSubmit.role);
    userToSubmit.isPerson = true;
    if (this.userDataFormGroup.get('dateOfBirth').hasError('ngbDate')) {
      this.toastService.showToast(
        'warning',
        'Corrija el formato de la fecha a AAAA-MM-DD',
      );
      return;
    }
    if (this.userDataFormGroup.invalid) {
      this.userDataFormGroup.markAllAsTouched();
      this.toastService.showToast(
        'warning',
        'Hay campos obligatorios sin completar',
      );
      return;
    } else {
      if (this.edit) {
        this.editUser(userToSubmit);
      } else {
        this.submitted = true;
        this.addUser(userToSubmit);
      }
    }
  }

  addUser(userToSubmit) {
    console.log(userToSubmit);
    this.userService.createUser(userToSubmit).subscribe(
      (response) => {
        this.toastService.showToast('success', 'Usuario agregado');
        this.closeAction.emit();
      },
      (error) => {
        //EN CASO DE QUE LE SIRVA A ALGUIEN EL BACK DEVUELVE COMO ERROR UN -1
        //ESTO SE PUEDE VER EN LA SIGUIENTE DIRECCION Rent-api\Baup.Services\Services\RealEstateAgentService.cs
        this.toastService.showToast(
          'error',
          'No se pudo agregar el usuario. Es posible que el MAIL esté en uso.',
        );
        this.closeAction.emit();
      },
    );
  }

  editUser(userForEdit) {
    userForEdit.id = this.editData.id;
    this.userService
      .updateUser(this.editData.id, userForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Usuario editado');
        this.closeAction.emit();
      });
  }

  patchValueAddObjectType() {
    var date = this.editData.dateOfBirth;

    this.userDataFormGroup.patchValue({
      dateOfBirth: date
        ? this.dateService.transformToDateHash(new Date(date))
        : null,
      firstName: this.editData.firstName,
      lastName: this.editData.lastName,
      documentId: this.editData.documentId,
      documentType: this.editData.documentType,
      streetAddress: this.editData.streetAddress,
      city: this.editData.city,
      state: this.editData.state,
      zipCode: this.editData.zipCode,
      email: this.editData.email,
      userName: this.editData.userName,
    });
  }

  selectUserType(value) {
    this.userTypeToggle = value;
  }

  validate(field: string) {
    return (
      this.userDataFormGroup.controls[field].errors &&
      this.userDataFormGroup.controls[field].touched
    );
  }

  cancel() {
    this.cancelAction.emit();
  }
}
