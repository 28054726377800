<div class="flex-row border-5 shadow-sm maindiv">
  <div class="flex-row" style="justify-content: space-between; flex-wrap: nowrap; width: 100%;" *ngIf="settings?.title">
    <div>
      <span style="font-size: 140%; margin-right: 1vw; font-weight: bold;">{{ settings?.title }}</span>
      <span class="badge text-bg-warning" style="font-size: 140%;font-weight: bolder; align-self: center;" *ngIf="settings.parent != 1 && fileArray?.length == 0">PENDIENTE</span>
    </div>
    <div *ngIf="!deleting && !settings?.isDisplay">
      <button class="btn btn-danger" (click)="cancel()" *ngIf="fileArray?.length > 0"><fa-icon style="margin-right: 5px;"[icon]="iconTrash"></fa-icon>Borrar</button>
      <button class="btn btn-primary" (click)="openForm(imgForm)" style="margin-left: 10px;"><fa-icon style="margin-right: 5px;"[icon]="iconPlus"></fa-icon>Agregar</button>
    </div>
    <div *ngIf="deleting && !settings?.isDisplay">
      <button class="btn btn-danger" (click)="delete()" [disabled]="selectedFiles.length < 1"><fa-icon style="margin-right: 5px;"[icon]="iconTrash"></fa-icon>
        Eliminar {{ selectedFiles.length == 0 ? '' : selectedFiles.length}} imagen{{ selectedFiles.length == 1 ? '' : 'es' }}
      </button>
      <button class="btn btn-primary" (click)="cancel()" style="margin-left: 10px;">Cancelar</button>
    </div>
  </div>
  <div class="flex-row" style="flex-wrap: wrap;">
    <div class="img_wrp animated" style="margin: 0.5rem;" *ngFor="let img of fileArray; let ind = index">
      <div *ngIf="img.type === 'application/pdf'" (click)="open(imgModal, img)" style="width: 175px; overflow: hidden;">
        <iframe class="picture" [src]="img.url | safe" type="application/pdf" height="175" width="200" style="pointer-events: none;"></iframe>
      </div>
      <img class="picture" (click)="open(imgModal, img)" [src]=img.url *ngIf="img.type.startsWith('image/')"/>
      <input type="checkbox" class="cbox" (change)="handleSelection(checkbox, ind)" #checkbox *ngIf="deleting"/>
    </div>
  </div>
</div>

<ng-template #imgModal>
  <div style="margin: 1rem; height: fit-content; width: fit-content;">
    <iframe [src]="(currentFile.url + '#zoom=100') | safe"  type="application/pdf" height="800" width="1000" *ngIf="currentFile.type === 'application/pdf'"></iframe>
    <img src="{{ currentFile.url }}" *ngIf="currentFile.type.startsWith('image/')" style="max-width:1000px; max-height: 800px;"/>
  </div>
</ng-template>

<ng-template #imgForm>
 
  <div class="flex-row" style="margin: 1rem; width: fit-content; height: 50vh;">
    <div class="flex-col" style="width: 40vw; height: 100%;">
      <div *ngIf="isLoading" style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center;">
        <ngb-progressbar type="info" textType="white" [value]="uploadProgress" [showValue]="true" [striped]="true" [animated]="true" ></ngb-progressbar>
      </div>
      <div  *ngIf="!isLoading" style="width: 100%; height: 100%;">
        <ngx-file-drop  (onFileDrop)="onFileDropped($event)" multiple="true"
        dropZoneLabel="Arrastre archivos aquí" dropZoneClassName="class-overriden" contentClassName="class-overriden"> 
        </ngx-file-drop>
      </div>
    </div>
    <div class="flex-col" style="margin-left: 1vw; width: 20vw;">
      <div class="flex-row" style="justify-content: center; height: 5.5vh;">
        <div class="flex-col">
          <button class="btn btn-primary" (click)="add()" style="margin: 0.5vh 0 1vh;">Seleccionar archivos</button>
        </div>
      </div>
      <div class="flex-row table-container" style="height: 39vh;">
        <div style="overflow-y: auto; overflow-x: hidden;">
          <table class="table table-striped modal-table">
            <thead style="position: sticky; top: 0; z-index: 1; background-color: white;">
              <tr>
                <th scope="col" style="width: 40%;">Nombre</th>
                <th scope="col" style="width: 35%;">Tipo</th>
                <th scope="col" style="width: 18%;">Tamaño</th>
                <th scope="col" style="width: 7%;"></th>
              </tr>
              <div style="margin-top: -1px; background-color: #dee2e6; width: 20vw; height: 1px;"></div>
            </thead>
            <tbody>
              <tr class="flex-row" *ngFor="let img of filesInMemory; let ind = index" style="width: 19.5vw;">
                <td style="width: 40%;"><span class="name-text">{{img.name}}</span></td>      
                <td style="width: 35%;">{{img.type}}</td>
                <td style="width: 18%;">{{img.size}}</td>
                <td style="width: 7%;"><fa-icon class="hoverred icons" (click)="removeItem(ind)" [icon]="iconTrash" size="1x"></fa-icon></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex-row" style="justify-content: center; height: 5.5vh;">
        <div class="flex-col">
          <button class="btn btn-success" (click)="upload()" [disabled]="isLoading" style="margin: 1vh 0 0.5vh;">Cargar archivos</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-confirm-dialog style="display:none;" [modalTitles]="confirmBoxTitles"></app-confirm-dialog>