import { ElementRef } from '@angular/core';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.css'],
})
export class MonthPickerComponent implements OnInit {
  @Input() locale: string;
  @Input() year: number;
  @Input() month: number;

  @Input() enabledMonths: Array<number> = [];
  @Input() disabledMonths: Array<number> = [];

  @Input() enabledYears: Array<number> = [];
  @Input() disabledYears: Array<number> = [];

  @Input() multiple: boolean;

  @Output() change = new EventEmitter<{ monthIndex: number; year: number }>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  model: MonthPickerModel;
  isCollapsed: boolean = true;
  tooltipText: string;
  visited: string;
  ChevronRight = faChevronRight;
  ChevronLeft = faChevronLeft;

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    if (this.locale) {
      moment.locale(this.locale);
    } else {
      moment.locale('es');
    }

    this.model = new MonthPickerModel();

    if (this.year) {
      this.model.selectedYearMoment = moment().year(this.year);
      this.model.updateYearText();
    }

    if (this.month) {
      this.model.selectedMonthIndex = this.month;
      this.model.selectedMonthMoment = moment().month(this.month);
      if (this.year) this.model.selectedMonthYear = this.year;
    }

    this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
  }

  decrementMonth() {
    if (this.isShowMonths) {
      this.model.decrementMonth();
      if (this.isShowYears) {
        this.renderMonths();
      }
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    } else if (!this.isShowYears) {
      this.model.decrementMonth();
      this.renderMonths();
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    }
  }

  incrementMonth() {
    if (this.isShowMonths) {
      this.model.incrementMonth();
      if (this.isShowYears) {
        this.renderMonths();
      }
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    } else if (!this.isShowYears) {
      this.model.incrementMonth();
      this.renderMonths();
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    }
  }

  decrementYear() {
    if (this.isShowYears) {
      this.model.decrementYear();
      if (this.isShowYears) {
        this.renderYears();
      }
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    }
  }

  incrementYear() {
    if (this.isShowYears) {
      this.model.incrementYear();
      if (this.isShowYears) {
        this.renderYears();
      }
      this.onChange(
        this.model.selectedMonthIndex,
        this.model.selectedMonthYear,
      );
    }
  }

  selectMonth(month: number) {
    this.isShowMonths = true;
    this.isShowYears = false;
    this.model.selectedMonthMoment = moment().month(month);
    this.model.updateMonthText();
    this.model.selectMonth(month);

    this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
  }

  isSelectedMonth(month: number) {
    return this.model.selectedMonthMoment.month() === month;
  }

  onChange(monthIndex: number, year: number) {
    this.change.emit({ monthIndex: monthIndex, year: year });
  }

  isDisabled(month: number) {
    let disabled = false;
    if (this.enabledMonths && this.enabledMonths.length > 0) {
      disabled = this.enabledMonths.findIndex((m) => m === month) < 0;
    }
    if (this.disabledMonths && this.disabledMonths.length > 0) {
      disabled = this.disabledMonths.findIndex((m) => m === month) >= 0;
    }
    return disabled;
  }

  isShowYears: boolean;
  years: Array<number> = [];
  toggleShowYears(x: string) {
    if (this.isCollapsed == true) {
      this.isCollapsed = false;
      this.isShowMonths = false;
      this.isShowYears = true;
      this.renderYears();
      this.visited = x;
    } else if (this.isCollapsed == false && x != this.visited) {
      this.isShowMonths = false;
      this.isShowYears = true;
      this.renderYears();
      this.visited = x;
    } else if (this.isCollapsed == false && x == this.visited) {
      this.isCollapsed = true;
      this.isShowMonths = false;
      this.isShowYears = false;
    }
  }

  isShowMonths: boolean;
  months: Array<number> = [];
  toggleShowMonths(x: string) {
    if (this.isCollapsed == true) {
      this.isCollapsed = false;
      this.isShowMonths = true;
      this.isShowYears = false;
      this.renderMonths();
      this.visited = x;
    } else if (this.isCollapsed == false && x != this.visited) {
      this.isShowMonths = true;
      this.isShowYears = false;
      this.renderMonths();
      this.visited = x;
    } else if (this.isCollapsed == false && x == this.visited) {
      this.isCollapsed = true;
      this.isShowMonths = false;
      this.isShowYears = false;
    }
  }

  renderYears() {
    this.years = [];
    for (let i = 5; i > 0; i--) {
      this.years.push(this.model.selectedYearMoment.year() - i);
    }
    for (let i = 0; i <= 6; i++) {
      this.years.push(this.model.selectedYearMoment.year() + i);
    }
  }

  renderMonths() {
    this.months = [];
    for (let i = 5; i > 0; i--) {
      this.months.push(this.model.selectedMonthMoment.month() - i);
    }
    for (let i = 0; i <= 6; i++) {
      this.months.push(this.model.selectedMonthMoment.month() + i);
    }
  }

  selectYear(year: number) {
    this.isShowYears = true;
    this.isShowMonths = false;
    this.model.selectedYearMoment = moment().year(year);
    this.model.updateYearText();
    this.model.selectedMonthYear = year;

    this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
  }

  isSelectedYear(year: number) {
    return this.model.selectedYearMoment.year() === year;
  }

  isDisabledYear(year: number) {
    let disabled = false;
    if (this.enabledYears && this.enabledYears.length > 0) {
      disabled = this.enabledYears.findIndex((y) => y === year) < 0;
    }
    if (this.disabledYears && this.disabledYears.length > 0) {
      disabled = this.disabledYears.findIndex((y) => y === year) >= 0;
    }
    return disabled;
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  close() {
    this.isCollapsed = true;
  }
}

export class MonthPickerModel {
  constructor() {
    this.selectedYearMoment = moment();
    this.updateYearText();

    this.selectedMonthMoment = moment();
    this.updateMonthText();

    this.months = moment.months();

    this.selectedMonthIndex = this.selectedMonthMoment.month();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  selectedYearMoment: moment.Moment;
  selectedYearText: string;
  selectedMonthText: string;

  selectedMonthMoment: moment.Moment;
  selectedMonthIndex: number;
  selectedMonthYear: number;

  months: Array<string> = [];

  updateYearText() {
    this.selectedYearText = moment(this.selectedYearMoment).format('YYYY');
  }

  updateMonthText() {
    this.selectedMonthText = moment(this.selectedMonthMoment).format('MMMM');
  }

  selectMonth(index: number) {
    this.selectedMonthMoment = moment().month(index);
    this.selectedMonthIndex = this.selectedMonthMoment.month();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  incrementYear() {
    this.selectedYearMoment = this.selectedYearMoment.add(1, 'year');
    this.updateYearText();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  decrementYear() {
    this.selectedYearMoment = this.selectedYearMoment.subtract(1, 'year');
    this.updateYearText();
    this.selectedMonthYear = this.selectedYearMoment.year();
  }

  incrementMonth() {
    if (this.selectedMonthIndex == 11) {
      this.incrementYear();
      this.selectMonth(0);
      this.updateMonthText();
    } else {
      this.selectedMonthMoment = this.selectedMonthMoment.add(1, 'month');
      this.updateMonthText();
      this.selectedMonthIndex = this.selectedMonthMoment.month();
    }
  }

  decrementMonth() {
    if (this.selectedMonthIndex == 0) {
      this.decrementYear();
      this.selectMonth(11);
      this.updateMonthText();
    } else {
      this.selectedMonthMoment = this.selectedMonthMoment.subtract(1, 'month');
      this.updateMonthText();
      this.selectedMonthIndex = this.selectedMonthMoment.month();
    }
  }
}
