<ng-template #content let-c="close" let-d="dismiss">
    <app-modal-confirm-container [title]="'Title'" [c]="c" [d]="d">
        <div class="confirm-dialog">
            <div class="confirm-title">
                <h1 style="margin: 1vh 1vh 1vh 1vh;">{{modalTitles.title | translate}}</h1>
                <span  style="margin: 1vh 1vh 1vh 1vh;">{{modalTitles.subtitle | translate}}</span>
                <span style="margin: 1vh 1vh 1vh 1vh;" *ngFor="let p of modalTitles.paragraphs">{{p}}</span>
                <div style="margin: 1vh 1vh 1vh 1vh;">
                    <ng-container *ngTemplateOutlet="modalBody ? modalBody: placeholderBody"></ng-container>
                </div>
            </div>
            <div class="confirmation">
                <button class="btn btn-danger"  style="margin: 1vh 1vh 1vh 1vh;" (click)="c(false)">{{"confirm.modal.cancel" | translate}} </button>
                <button class="btn btn-primary" style="margin: 1vh 1vh 1vh 1vh;" (click)="c(true)"> {{"confirm.modal.accept" | translate}}</button>
                @if (isShowing(modalTitles.btnadd)) {               
                <button  class="btn btn-primary" style="margin: 1vh 1vh 1vh 1vh;" (click)="c('stock')"> Agregar a Stock</button> 
                }            
            </div>
        </div>
    </app-modal-confirm-container>
</ng-template>


<ng-template #placeholderBody>
   
</ng-template>

