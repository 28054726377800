<div class="grid shadow-sm border-5" [ngStyle]="{'width': tableWidth}">

  <div class="title">
    <span *ngIf="settings.urlForRedirect" [ngbTooltip]="'Ver más'" class="viewMore" (click)="viewMore()"
      style="font-size: 1.10rem; font-weight: 550;">{{settings.header}}</span>
    <span *ngIf="!settings.urlForRedirect" style="font-size: 1rem; font-weight: 550;">{{settings.header}}</span>
    <span *ngIf="settings.quantity && quantity  > 0" class="badge text-bg-dark"
      style="font-size: 1rem; font-weight: 550;">{{quantity}}</span>
  </div>

  <loading-spinner [evaluation]="isLoaded" [liteMode]="true" style="height: 100%; margin-top: 1vh;" class="spinner">
    <div class="table-grid table-responsive-sm" style="height: 100%;">
      <ng-container *ngIf="renderData.length > 0 else Norows" style="height: 100%;">
        <div class="table-template table-responsive-sm ">
          <table class="table table-md rounded">
            <thead>
              <tr class="fila-hover" *ngIf="settings.header" class="grid-headers">
                <th scope="col" *ngFor="let header of headers">
                  <div class="td-info">
                    {{ header | translate}}
                  </div>
                </th>
              </tr>
            </thead>
            <ng-template *ngIf="renderData.length == 0 else Render">
              <tbody>
                <div>{{'gridComponent.tableEmpty' | translate}}</div>
              </tbody>
            </ng-template>
          </table>
        </div>
      </ng-container>
    </div>
  </loading-spinner>

  <ng-template #Render>
    <tbody>
      <tr class="grid-rows-style" (click)="detailRedirect(tableData[ind], settings.baseRowURL, settings.endRowURL)"
        [ngClass]="{'urlPointer' : !!settings.baseRowURL}" *ngFor="let row of renderData; let ind = index">
        <td *ngFor="let item of settings.columns; let j = index">
          <div class="flex-row" style="justify-content: center;">
            <fa-icon *ngIf="item.key == 'icon'" [icon]="iconService.getIcon(row[j])">
            </fa-icon>
            <span *ngIf="item.key == 'index'">
              {{ind + 1}}
            </span>
            <span *ngIf="item.key != 'icon' && item.key != 'index'  && !item.type">
              <span *ngIf="checkArray(row[j])">
                <span *ngFor="let rowItem of row[j]; let rowIndex = index">
                  <span *ngIf="item.urlFunction" (click)="item.urlFunction(tableData[ind][item.key][rowIndex])"
                    [ngbTooltip]="'Ver más'" placement="bottom" class="hoverblue">
                    {{rowItem}}
                  </span>
                  <span *ngIf="!item.urlFunction">
                    {{rowItem}}
                  </span>
                </span>
              </span>
              <span *ngIf="!checkArray(row[j])">
                <span *ngIf="item.urlFunction" (click)="item.urlFunction(tableData[ind][item.key])"
                  [ngbTooltip]="'Ver más'" placement="bottom" class="hoverblue">
                  {{row[j]}}
                </span>
                <span *ngIf="!item.urlFunction">
                  {{row[j]}}
                </span>
              </span>
            </span>
            <span *ngIf="item.type == 'amount'">
              <span>
                {{row[j] | currency:
                (tableData[ind][item.currency] === 2 ? 'USD' : 'ARS') : (tableData[ind][item.currency] === 1 ?
                'symbol-narrow' :
                'symbol') : '1.0-0' }}
              </span>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </ng-template>

  <ng-template #Norows style="height: 100%;">
    <div class="table-empty">
      <fa-icon style="margin-bottom: 5px;" [icon]="iconQuestion" size="4x"></fa-icon>
      <span style="margin-bottom: 5px;">{{'gridComponent.tableEmpty' | translate}}</span>
    </div>
  </ng-template>

</div>